<template>
  <div>
    <StudentFAQ />
    <div>
      <h2>What are my obligations once I have a medical certificate?</h2>
      <p>
        Once you have
        <router-link to="/how-to-apply-for-medical">
        obtained an aviation medical certificate
        </router-link>, you are required to meet certain legal obligations. For example,
        if you become temporarily unfit (e.g. injury), or start a new medication, you may
        be required to consult a Designated Aviation Medical Examiner (DAME) before
        continuing your flight training. You are also required to carry a physical copy of
        your medical certificate onboard the aircraft during your flight training lessons.
        We recommend you keep a copy of your medical certificate in your
        <router-link to="/how-to-purchase-aviation-headset">
        aviation headset bag or box
        </router-link> so that you can be assured that you always have it with you when
        flying.
      </p>
      <p>
        Your obligations are dependent on a number of factors, such as your age, your
        class of medical certificate, the type of medication you use and many others. If you are in any
        doubt about your obligations as an aviation medical certificate holder, please ask
        an instructor for help.
      </p>
      <p>
        The legislation that pertains to medical certificate holders can be found by using
        the search for
        <quote>"Civil Aviation Regulations 1988"</quote>
        and
        <quote>"Civil Aviation Safety Regulations 1998"</quote> on
        <a href="https://legislation.gov.au">legislation.gov.au</a>.
      </p>
    </div>
    <MedicalCertificateObligations />
  </div>
</template>

<script>
import StudentFAQ from '@/components/StudentFAQ.vue'
import MedicalCertificateObligations from '@/components/MedicalCertificateObligations.vue'

export default {
  components: {
    StudentFAQ,
    MedicalCertificateObligations
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/_presets.scss';
</style>
